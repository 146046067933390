import { helpers, required } from 'vuelidate/lib/validators';
export const mail = helpers.regex(
  'mail',
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const name = helpers.regex('name', /^(?=.{1,40}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/);

export const telNumber = helpers.regex(
  'telNumber',
  /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
);

export const userValid = {
  id: {},
  firstName: { required, name },
  lastName: { required, name },
  email: { required, mail },
  phone: { telNumber },
  site: { required },
  role: {required},
  firstPage: {},
  divisions: {},
};

export const userData = {
  id: null,
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  role: null,
  firstPage: 'imp',
  divisions: null,
  site: null,
  active: true
}