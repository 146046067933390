






























































































































































































































import Loading from '@/shared/components/Loading.vue';
import { UserManagementService } from '@/shared/services/user-management/user-management.service';
import { DivisionModule, UserManagementModule } from '@/store';
import { ToastHelper } from '@/utils/toast.util';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Prop } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { userData, userValid } from '../@types/user.model';

Component.registerHooks(['validations']);
@Component({
  components: { Loading },
  mixins: [validationMixin]
})
export default class UserModal extends Vue {
  @Prop() userId: any;
  @Prop() roles: any;
  @Prop() divisions: any;
  loading = false;
  userManagementModule = UserManagementModule;
  divisionModule = DivisionModule;

  selectedUser = this.userManagementModule.user;
  roleOptions = [];
  divOptions = [];
  firstPageOptions = [
    { text: 'IMP', value: 'imp' },
    { text: 'EXP', value: 'exp' },
    { text: 'VAN', value: 'van' }
  ];

  allSelected = false;
  indeterminate = false;

  errorModal = false;
  user = null;
  form = this.user;

  masks = {
    telNumMask: [
      '(',
      /\d/,
      /\d/,
      /\d/,
      ') ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ]
  };

  constructor() {
    super();
  }

  async created() {
    this.user =
      this.userId && this.selectedUser
        ? this.selectedUser
        : this.getInitialUserModalData();
    this.userId = this.user.id;
    this.form = this.user;
    this.divOptions = this.divisions;
    this.roleOptions = this.roles;
  }

  getInitialUserModalData() {
    return JSON.parse(
      JSON.stringify({
        ...userData,
        site: this.divisionModule.defaultDivision
      })
    );
  }

  toggleAll(checked) {
    this.form.divisions = checked
      ? this.divOptions.map(a => a.value).slice()
      : [];
  }

  @Watch('form.divisions', { deep: true, immediate: true })
  onSelectionChange(newValue, oldValue) {
    if (newValue && newValue.length === 0) {
      this.indeterminate = false;
      this.allSelected = false;
    } else if (newValue && newValue.length === this.divOptions.length) {
      this.indeterminate = false;
      this.allSelected = true;
    } else {
      this.indeterminate = true;
      this.allSelected = false;
    }
  }

  async createUser() {
    if (this.isFormInValid()) return;
    const userData = {
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      email: this.user.email,
      firstPage: this.user.firstPage ? this.user.firstPage : 'imp',
      role: this.user.role,
      site: this.user.site,
      divisions: this.user.divisions,
      phone: '',
      active: this.user.active
    };
    if (Boolean(this.user.phone))
      userData.phone = this.user.phone.replace(/\D/g, '').toString();

    try {
      this.loading = true;
      if (this.userId) {
        await UserManagementService.updateUser(this.userId, userData);
        ToastHelper.show(
          'User',
          'User data updated successfully',
          5000,
          'success'
        );
      } else {
        userData.active = true;
        await UserManagementService.createUser(userData);
        ToastHelper.show('User', 'User created successfully', 5000, 'success');
      }

      this.closeUser();
      this.userManagementModule.search();
    } catch (err) {
      console.log('user creation Error', err);
      ToastHelper.show('User', err.response.data?.error, 5000, 'danger');
    } finally {
      this.loading = false;
    }
  }

  closeUser() {
    this.$bvModal.hide('user-modal');
  }

  validateState(val: any) {
    if (this.$v && this.$v.form && this.$v.form[val]) {
      const { $dirty, $error }: any = this.$v.form[val];
      return $dirty ? !$error : null;
    }
  }

  get requiredFields(): Set<string> {
    return new Set(
      Object.keys(userValid).filter(fieldName => userValid[fieldName].required)
    );
  }

  isFormInValid() {
    this.$v.form.$touch();
    if (this.$v.form.$anyError) {
      this.errorModal = true;
    }
    return this.$v.form.$anyError;
  }

  validations() {
    return {
      form: userValid
    };
  }

  validateNotMandatory(val: any) {
    if (this.$v && this.$v.form && this.$v.form[val]) {
      const { $dirty, $error }: any = this.$v.form[val];
      if (!this.user[val] || this.user[val].trim() == '') {
        return null;
      }
      return $dirty ? !$error : null;
    }
  }
}
