




























































































import { UserListHeaders } from '@/pages/Admin/UserManagement/models/UserListHeaders';
import { TableHeaders } from '@/shared/components/table/models';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { limit } from '@/shared/services/base/api.service';
import { UserManagementModule } from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import UserFilter from './UserFilter/UserFilter.vue';
import UserModal from './UserModal/UserModal.vue';
import { UserManagementService } from '@/shared/services/user-management/user-management.service';
import { ToastHelper } from '@/utils/toast.util';
import Loading from '@/shared/components/Loading.vue';
import { AdminConfService } from '@/shared/services/admin-conf/admin-conf.service';
import { RolesService } from '@/shared/services/roles/roles.service';
import { ConfigType } from '../Configuration/model/Config.model';

@Component({
  components: { TmsTable, UserFilter, UserModal, Loading }
})
export default class UserManagement extends Vue {
  columns: TableHeaders[] = UserListHeaders;

  loading = false;
  handlerModal = false;
  innerHandlerMessage = null;

  pageLimit = limit;
  userManagementModule = UserManagementModule;
  modalTitle = '';

  userId = null;
  resetMsg = '';

  divOptions = [];
  roleOptions = [];

  constructor() {
    super();
  }

  async mounted() {
    const divisionsList = await AdminConfService.getData(ConfigType.DIVISION);
    if (divisionsList) {
      this.divOptions = divisionsList
        .filter(a => a.active)
        .map(i => ({
          value: i.abbr,
          text: i.abbr
        }));
    }

    const data = await RolesService.getRolesTableData();
    if (data) {
      this.roleOptions.push(
        ...data
          .filter(
            ({ role }) =>
              !['carrier','driver','subdriver'].includes(role.toLocaleLowerCase())
          )
          .map(i => ({ value: i.role, text: i.role }))
      );
    }
  }

  get tableData() {
    return this.userManagementModule.users;
  }

  get isLastPage(): boolean {
    return !UserManagementModule.meta.lastKey;
  }

  onInputChange({ field, data }) {
    switch (field) {
      case 'active':
        this.changeActive(data);
        break;
      default:
        break;
    }
  }
  async changeActive(user: any) {
    // call service to update status field for an user.
    this.userManagementModule.setLoadingStatus(true);
    try {
      await UserManagementService.changeUserActive(user.id, user.active);
    } catch (err) {
      console.log('User Status Change Error', err);
      ToastHelper.show('User', err.response.data?.error);
    } finally {
      this.userManagementModule.setLoadingStatus(false);
    }
  }

  action(type: { key: string; data: any }) {
    console.log('key', type.key, type.data);

    switch (type.key) {
      case 'edit':
        this.openEditModal(type.data);
        break;
      case 'reset':
        this.openResetModal(type.data);
        break;
      case 'delete':
        this.openDeleteModal(type.data);
        break;
    }
  }

  openDeleteModal(data: any) {
    console.log('Delete button clicked');
    this.userId = data.id;
    this.modalTitle = `${data.name} - Delete User`;
    this.$bvModal.show('delete-user-modal');
  }

  async deleteUser() {
    await this.userManagementModule.delete(this.userId);
    this.hideModal('delete-user-modal');
  }

  async openEditModal(data: any) {
    console.log('Edit button clicked');
    this.modalTitle = `${data.name} - User Details`;
    this.userId = data.id;
    await this.userManagementModule.getUserById(this.userId);
    this.$bvModal.show('user-modal');
  }

  openResetModal(data: any) {
    console.log('Reset button clicked');
    this.userId = data.id;
    this.modalTitle = `${data.name} - Reset Password`;
    this.resetMsg = this.$t(`userManagement.resetConfirmMessage`, {
      userId: data.id,
      email: data.email
    }).toString();
    this.$bvModal.show('reset-user-modal');
  }

  async sendResetEmail() {
    await this.userManagementModule.passwordReset(this.userId);
    this.$bvModal.hide('reset-user-modal');
  }

  hideModal(id: string) {
    this.$bvModal.hide(id);
  }
}
