










































































































import { UserType } from '../models/user-filter.model';
import DIVFilter from '@/shared/components/filters/DIVFilter.vue';
import {
  FilterConditions,
  FilterInput,
  TransformFiltersToJson
} from '@/shared/services/filter/filter.service';
import { Component, Vue, Watch } from 'vue-property-decorator';
import Loading from '@/shared/components/Loading.vue';
import { ToastHelper } from '@/utils/toast.util';
import { UserManagementModule } from '@/store/index';

@Component({ components: { DIVFilter, Loading } })
export default class UserFilter extends Vue {
  loading = false;
  userManagementModule = UserManagementModule;
  filterBy = 'id';

  statusOptions = [
    { value: null, text: 'All' },
    { value: true, text: 'Active' },
    { value: false, text: 'Inactive' }
  ];

  filterByOptions = [
    { value: 'id', text: 'ID' },
    { value: 'firstName', text: 'First Name' },
    { value: 'lastName', text: 'Last Name' }
  ];

  DIVFilter = new FilterInput({
    condition: FilterConditions.Equal,
    search: null,
    field: 'division'
  });

  StatusFilter = new FilterInput({
    condition: FilterConditions.Equal,
    search: null,
    field: UserType.Status
  });

  FilterBy = new FilterInput({
    condition: FilterConditions.Equal,
    search: null,
    field: this.filterBy
  });

  @Watch('filterBy', { immediate: true })
  onPropertyChanged(value: string, oldValue: string) {
    this.FilterBy = new FilterInput({
      condition: FilterConditions.Equal,
      search: null,
      field: this.filterBy
    });
  }

  constructor() {
    super();
  }

  created() {
    this.search();
  }

  async search() {
    this.loading = true;
    try {
      this.userManagementModule.setFilters(
        TransformFiltersToJson([
          this.DIVFilter,
          this.StatusFilter,
          this.FilterBy
        ])
      );
      this.userManagementModule.setPage(1);
      await this.userManagementModule.search();
    } catch (err) {
      ToastHelper.show('Users search failed', err.message, 5000, 'danger');
    }
    this.loading = false;
  }

  async excel() {}

  resetFilters() {
    this.DIVFilter = new FilterInput({
      condition: FilterConditions.Equal,
      search: null,
      field: 'division'
    });

    this.StatusFilter = new FilterInput({
      condition: FilterConditions.Equal,
      search: null,
      field: UserType.Status
    });

    this.FilterBy = new FilterInput({
      condition: FilterConditions.Equal,
      search: null,
      field: this.filterBy
    });

    this.userManagementModule.setFilters(
      TransformFiltersToJson([this.DIVFilter, this.StatusFilter, this.FilterBy])
    );
  }
}
